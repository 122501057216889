<template>
  <div>
      <!--  ========================= HEADER ========================= -->
      <div class="page-header d-flex align-items-end">
        <h1 class="m-0">
          <span class="font-weight-bolder">
            <span class="text-primary bg-dark-blue rounded px-1 mr-1">FiqOn</span>
          </span>
          <span class="text-ifood cursor-pointer" >
          iFood
          </span>
        </h1>
        <h2 class="ml-1 mb-0">Loja Demo</h2>
      </div>
      <!--  ========================= HEADER ========================= -->
        
      
      <!-- {{statusLoja}} -->

      <div class="mx-2">

        <div align="right" class="m-2 small text-secondary" :key="timerCountKey">
          Recarregando em {{getTimeUntilRefresh()}} segundos
        </div>  

        

        <div class="small text-secondary" v-if="statusLoja">
          status: {{statusLoja.status}}
        </div>
        <div v-if="statusLoja" class="d-flex align-items-end">
          Estado da Loja: 
          <template v-if="statusLoja.status == 'OK'">
            <h4 class="text-success mb-0 ml-1">{{statusLoja.message}}</h4>
          </template>
          <template v-else-if="statusLoja.status == 'CLOSED'">
            <h4 class="text-danger mb-0 ml-1">{{statusLoja.message}}</h4>
          </template>
          <template v-else>
            <h4 class="text-light mb-0 ml-1">{{statusLoja.message}}</h4>
          </template>
        </div>

        <div v-if="statusLoja && statusLoja.status != 'OK'" class=" mx-50 mb-1 small">
          <div v-for="(val , idx) in statusLoja.validacoes" :key="'validacao-'+idx" class="mb-50">
            <span class="font-weight-bold mr-50">
              {{val.title}}
              <template v-if="val.subtitle && val.subtitle.length > 0">:</template>
            </span>
            <span>{{val.subtitle}}</span>
          </div>
        </div>
        
        <div v-if="pedidos && statusLoja">
          <div v-for="(tipo) in  Object.keys(tiposPedidos)" :key="'tipo-pedidos-'+tipo">
            <div v-if="Array.isArray(pedidos[tipo]) && pedidos[tipo].length > 0">
              
              <h2 class="mb-0 mt-1 pl-1">
                <b-icon icon="square-fill" scale="0.5" :style="`color: ${tiposPedidos[tipo].color};`"/>
                {{tiposPedidos[tipo].label}}
              </h2>
              <div class="pedidos-outer-container">
                <div class="pedidos-container flex-wrap">
                
                  <div class="mt-25 px-1 pedido-card position-relative" v-for="(pedido, idx) in pedidos[tipo]" :key="'pedido-card-'+ pedido.order_id" :style="`border-color:${tiposPedidos[tipo].color};`">
                    <div class="created-at-position">
                      {{treatTime(pedido.created_at)}}
                    </div>

                    <div class="small mb-50">
                      <div>Cliente: <span class="text-light">{{pedido.customer}}</span></div>
                      <div v-if="pedido.client_document_number">CPF/CNPJ: {{pedido.client_document_number}}</div>
                    </div>
                    
                    
                    <div class="entrega-container mb-2">
                      <div v-if="pedido.orderTiming == 'IMMEDIATE'">
                        Entrega: <span class="font-weight-bolder text-success">Imediata</span>
                      </div>

                      <div v-else>
                        Entrega: <span class="font-weight-bolder text-info">Agendada</span>
                        <div class="px-1">
                          Início: <span class="ml-50 text-info">{{treatTime(pedido.scheduled_start)}}</span>
                        </div>
                        <div class="px-1">
                          Fim: <span class="ml-50 text-info">{{treatTime(pedido.scheduled_end)}}</span>
                        </div>
                      </div>
                      <div v-if="pedido.orderType" class="mt-25">
                        Tipo de pedido: <span class="font-weight-bolder text-danger">{{pedido.orderType}}</span>
                      </div>
                      <div v-else class="mt-25">
                        Tipo de pedido: <span class="font-weight-bolder text-warning">TAKEOUT</span>
                      </div>
                    </div>
                    


                    <h5 class="mb-25">Itens pedidos:</h5>
                    <div class="items-container">

                      <div v-for="(item) in pedido.items" class="pedido-item">
                        <div class="">{{item.item}}</div>

                        <div class="small" v-for="(opt) in item.options">
                          <b-icon icon="circle-fill" scale="0.35"/>
                          {{opt.name}} <span class="ml-50 small">( {{opt.quantity}} )</span>
                        </div>

                        <div v-if="item.observations" class="pedido-observation">
                          Obs: {{item.observations}}
                        </div>
                        <div align="right" class="small mt-50">
                          quant. {{item.quantity}}
                        </div>
                      </div>
                      
                    </div>
                    

                    <div v-if="pedido.delivery_observations" class="mt-25 mb-1 font-weight-bold">
                       Obs. {{pedido.delivery_observations}}
                    </div>


                    <div class="small text-secondary mx-25" v-if="pedido.display_id">
                      Identificador do pedido: {{pedido.display_id}}
                    </div>

                    <div class="msmall pagament-info-container rounded d-flex flex-wrap">
                      <div align="center" class="title text-light font-weight-bolder rounded w-100">Pagamento</div>
                    
                      
                      <div class="benefit-container w-100" v-if="pedido.benefits && Array.isArray(pedido.benefits)">
                        <div class="font-weight-bolder">Cupons:</div>
                        <template v-for="(benefit, bIdx) in pedido.benefits">
                          <template v-for="sponsor in benefit.sponsorship">
                            <div v-if="sponsor.value > 0" class="mx-50">
                              {{sponsor.name}}
                              <span class="mr-50">({{sponsor.description}}):</span>
                              <span>Valor: {{sponsor.value.toFixed(2)}} R$</span>
                            </div>
                          </template>

                        </template>
                      </div>
                      
                      
                      <div v-for="(payment, idx) in pedido.payments" :key="JSON.stringify(payment)" class="mt-50 d-flex align-items-center flex-wrap justify-content-left">
                        <div class="mr-50">Total: <b>{{formatCurrency(payment.value, payment.currency)}}</b></div>
                        
                        <div class="small mr-50 bg-success rounded px-25 font-weight-bolder text-black" v-if="parseInt(pedido.has_to_pay) == 0 || !pedido.has_to_pay">PAGO</div>
                        <div class="small mr-50 bg-warning rounded px-25 font-weight-bolder text-black" v-else>
                          A PAGAR: <span class="text-black">{{formatCurrency(pedido.has_to_pay, payment.currency)}}</span>
                        </div>
                        
                        <div class="mr-1" v-if="payment.card_brand">
                          Bandeira: <span class="font-weight-bold">{{payment.card_brand}}</span>
                        </div>
                        <div class="mr-1" v-if="(payment.change || payment.change==0) && calculateChange(payment.changeFor, pedido.has_to_pay) > 0">
                          Troco: 
                          <span class="font-weight-bold">{{formatCurrency((calculateChange(payment.changeFor, pedido.has_to_pay)), payment.currency)}}</span>
                           - (cliente pagará 
                           <span class="font-weight-bold">{{formatCurrency(payment.changeFor, payment.currency)}}</span>)

                        </div>
                        <div class="mr-1">Método de pagamento: <span class="font-weight-bold">{{payment.method}}</span></div>
                      </div>

                    </div>
                    

                    <div v-if="tipo == 'PLC'">  <!-- NOVO PEDIDO -->
                      <div class="d-flex align-items-center mt-50">
                        <hr class="m-0 mx-50 w-50">
                        <b-icon icon="circle-fill" scale="0.35" variant="secondary"/>
                        <hr class="m-0 mx-50 w-50">
                      </div>

                      <div class="d-flex justify-content-between mt-50">
                        <b-button variant="danger" class="" size="sm" @click="openCancelamento(pedido)">Cancelar</b-button>
                        <b-button variant="success" class="" size="sm" @click="confirmarPedido(pedido)">Confirmar</b-button>
                      </div>
                    </div>
                    
                    <div v-if="tipo == 'CFM'">  <!-- PEDIDO CONFIRMADO -->
                      <div class="d-flex align-items-center mt-50">
                        <hr class="m-0 mx-50 w-50">
                        <b-icon icon="circle-fill" scale="0.35" variant="secondary"/>
                        <hr class="m-0 mx-50 w-50">
                      </div>

                      <div class="d-flex justify-content-between mt-50">
                        <b-button variant="danger" class="invisible" size="sm" @click="openCancelamento(pedido)">Cancelar</b-button>
                        <b-button variant="success" class="" size="sm" @click="dispatchPedido(pedido)">Despachar</b-button>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="mx-3 mt-1 d-flex justify-content-evenly flex-wrap">
          <b-skeleton v-for="i in 10" height="350px" width="300px" class="mx-1" animation="fade" :key="'skeleton-load-'+i"/>
        </div>
      </div>

      <b-modal
        size="md"
        ref="modal-cancelar-pedido"
        no-close-on-backdrop
        centered
        title="Cancelando Pedido"
        ok-only
        ok-variant="danger"
        ok-title="cancelar pedido"
        @ok="efetivarCancelamento()"
        :ok-disabled="!dadosCancelamento.motivo"
      >
          <div class="mb-25">Selectione um motivo para o cancelamento:</div>
          <div v-if="cancelModalInfo">
            <v-select :options="cancelModalInfo" :getOptionLabel="(opt)=>{return opt.description}" v-model="dadosCancelamento.motivo">
              <template #option=data>
                <span class="small mr-50 bg-dark-blue rounded p-25 text-success">{{data.cancelCodeId}}</span> {{data.description}}
              </template>

              <template #selected-option=data>
                <span class="small mr-50 bg-dark-blue rounded p-25 text-success">{{data.cancelCodeId}}</span> {{data.description}}
              </template>
            </v-select>

          </div>
          <div v-else>
          <b-skeleton height="40px" width="100%" class="m-0"/>
        </div>

      </b-modal>
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BIcon,
  BSkeleton,
  BCollapse,
  BTabs,
  BTab,
  BButton,
  BFormInput,
  BFormCheckbox,
  BFormTextarea,
  BOverlay,
  BSkeletonTable,
 } from 'bootstrap-vue';
import axios from 'axios';
import moment from 'moment'
import vSelect from 'vue-select'

 export default {
  components: {
    BCol,
    BRow,
    BIcon,
    BSkeleton,
    BCollapse,
    BTabs,
    BTab,
    BButton,
    BFormInput,
    BFormCheckbox,
    BFormTextarea,
    BOverlay, 
    BSkeletonTable,
    vSelect,
  },
  data() {
    return {
      pedidos: undefined,
      pedidosRefreshTimer: undefined,
      timerCountKey: 0,
      statusLoja: undefined,
      awaitTime: 30000,
      
      tiposPedidos:{
        PLC: {label: "Novos Pedidos", color: "white"},
        
        CFM: {label: "Pedidos Confirmados", color: "springgreen"},    //3
        RTP: {label: "Pedidos prontos para retirar", color: "LightSkyBlue"},  //2
        DSP: {label: "Pedidos em entrega", color: "DarkSlateBlue"},   //2

        CON: {label: "Pedidos Concluidos", color: "DarkGreen"},  //first in precedence
        CAN: {label: "Pedidos Cancelados", color: "firebrick"},   //first in precedence
      },
      cancelModalInfo: undefined,
      dadosCancelamento: {
        order_id: undefined,
        motivo: undefined,
      },
    }
  },
  mounted () {
    this.init();
  },
  methods: {
    init(){
      this.eventsRefreshLoop()
      this.statusRefreshLoop()
      this.timerRefreshLoop()
    },
    eventsRefreshLoop(){
      this.pedidosRefreshTimer = new Date()
      this.fetchEvents()
      setTimeout(() => {
        this.eventsRefreshLoop()
      }, this.awaitTime);
    },
    statusRefreshLoop(){
      this.pedidosRefreshTimer = new Date()
      this.fetchStatus()
      setTimeout(() => {
        this.statusRefreshLoop()
      }, this.awaitTime);
    },
    timerRefreshLoop(){
      this.timerCountKey = this.timerCountKey + 1
      setTimeout(() => {
        this.timerRefreshLoop()
      }, 1000);
    },
    fetchEvents(){
      this.storeStateLocally()

      let url = "https://instance.fique.online/webhook/4168b8bb-f5a0-40c8-adab-e41c6469b94a/Events%20Pooling/66b04ca7360b502fef5016eab5e356cd00b9f0363d57f7c60827d5835106dd19b176d1fabc"
      axios.post(url)
      .then((resp)=>{
        
        console.log('%c'+JSON.stringify(resp.data), 'color: gold');
        
        if (!resp.data.IFOOD){
          console.error("fetch events failed")
          return
        }

        let local = localStorage.getItem('TEMPORARY_IFOOD_STORAGE')

        if (local){
          local = JSON.parse(local)
        } else {
          local = {
            PLC: [],
            CFM: [],
            RTP: [],
            DSP: [],
            CON: [],
            CAN: [],
          }
        }
        
        let result =  {
          PLC: [],
          CFM: [],
          RTP: [],
          DSP: [],
          CON: [],
          CAN: [],
        }
        let incoming = structuredClone(resp.data.IFOOD.events_info)

        const tipos = [ "CON","CAN","DSP", "RTP", "CFM", "PLC"]
        let orderIds = []
        
        tipos.forEach(tipo => {

          if (incoming && Array.isArray(incoming[tipo])){

            incoming[tipo].forEach(pedido => {

              if (!orderIds.includes(pedido.order_id)){

                if (Object.keys(pedido).length < 3){ // check ifs it's s full Order or just a category indicator
                  let temp;
                  
                  Object.keys(local).forEach(tempTipo => {
                    local[tempTipo].forEach(order => {
                      if (order.order_id == pedido.order_id){
                        temp = order
                      }
                    });
                  });

                  pedido = temp
                }
                
                if (pedido != undefined){
                  result[tipo].push(pedido)
                  orderIds.push(pedido.order_id)
                }
                

              }
            })
            
          }


          local[tipo].forEach(pedido => {

            if (!orderIds.includes(pedido.order_id)){
              result[tipo].push(pedido)
              orderIds.push(pedido.order_id)
            }
          });
          
        });

        this.pedidos = result
        this.storeStateLocally()
        // this.pedidos = resp.data.IFOOD.events_info.PLC
      })
      .catch((err)=>{
        console.error(err)
      })
    },
    fetchStatus(){
      let url = "https://instance.fique.online/webhook/4168b8bb-f5a0-40c8-adab-e41c6469b94a/Status Loja/e1b3a6108bc9f1d7d092ea211828e4dd02576658e6b701b19f1a52705ac74d129e6ef6a294"
      axios.post(url)
      .then((resp)=>{        
        // console.log('%c'+JSON.stringify(resp.data), 'color: orange');

        if (resp.data.IFOOD){
          this.statusLoja = resp.data.IFOOD.status_loja[0]
        } else {
          console.error('failed fetch status loja')
        }
      })
      .catch((err)=>{
        console.error(err)
      })
    },
    fetchMotivosCancelamento(order_id){
      let url = "https://instance.fique.online/webhook/4168b8bb-f5a0-40c8-adab-e41c6469b94a/Razoes Cancelamento/f72ef3e445f5cc8f49c6d535c26ef5fddf9dd06ca11358625cba27dc274670b2952c3f2a7a";
      let body = {
        order_id: order_id
      }
      axios.post(url, body)
        .then((resp)=>{

          if (resp.data.IFOOD){
            this.cancelModalInfo = resp.data.IFOOD
          } else {
            console.error('failed fetch mootivos')
          }
        })
        .catch((err)=>{
          console.error(err)
        })
    },
    getTimeUntilRefresh() {
      return parseInt((this.awaitTime/1000) - (new Date() - this.pedidosRefreshTimer)/1000)
    },
    treatTime(time){
      moment.locale('pt-br')
      return moment(time).calendar()
    },
    confirmarPedido(pedido){
      this.$store.dispatch("setLoadingState", true);
      let url = "https://instance.fique.online/webhook/4168b8bb-f5a0-40c8-adab-e41c6469b94a/Confirmar Pedidos/a0f0e1c607c38e96f516f21ae5e6c5557b9c60a7a6ec23e3cc6d63494b8507eb546ad05935"
      let body = [ {order_id: pedido.order_id} ]
      axios.post(url, body)
      .then((resp)=>{
        this.changeCategoryPedido(pedido.order_id, 'CFM')
      })
      .catch((err)=>{
        console.error(err)
      })
      .finally(()=>{
        this.$store.dispatch("setLoadingState", false);
      })
    },
    dispatchPedido(pedido){
      this.$store.dispatch("setLoadingState", true);
      let url = "https://instance.fique.online/webhook/4168b8bb-f5a0-40c8-adab-e41c6469b94a/despachar_pedidos/497fd6d2efb53c7fcaa9ccf5cde3c9b58e0271604f2ff7f6236ed052f447858379b6e66e37"
      let body = [
        {
          order_id: pedido.order_id,
          type: pedido.orderType    //"DELIVERY" // || "TAKEOUT"
        }
      ]
      axios.post(url, body)
      .then((resp)=>{
        if (pedido.orderType == "DELIVERY"){
          this.changeCategoryPedido(pedido.order_id, 'DSP')
        } else {
          this.changeCategoryPedido(pedido.order_id, 'RTP')
        }
      })
      .catch((err)=>{
        console.error(err)
      })
      .finally(()=>{
        this.$store.dispatch("setLoadingState", false);
      })
    },
    openCancelamento(pedido){
      this.dadosCancelamento = {
        order_id: undefined,
        motivo: undefined,
      },

      this.dadosCancelamento.order_id = pedido.order_id
      this.cancelModalInfo = undefined
      this.fetchMotivosCancelamento(pedido.order_id)

      this.$refs['modal-cancelar-pedido'].show()
    },
    efetivarCancelamento(){
      let body = [{
        order_id: this.dadosCancelamento.order_id,
        reason: this.dadosCancelamento.motivo.description,
        cancel_code: this.dadosCancelamento.motivo.cancelCodeId,
      }]
      let url = "https://instance.fique.online/webhook/4168b8bb-f5a0-40c8-adab-e41c6469b94a/Solicitar Cancelamento/79bfaa1a929ca9ef95d6d9f8b62549a3d86babfed2794e29d320d1549354dcdc622fddc94f"
      axios.post(url, body)
      .then((resp)=>{
        this.changeCategoryPedido(this.dadosCancelamento.order_id, 'CAN')
      })
      .catch((err)=>{
        console.error(err)
      })
    },
    changeCategoryPedido(order_id, newCategory){
      let pIdx;
      let oldCategory;
      Object.keys(this.pedidos).forEach(cat => {
        let t = this.pedidos[cat].findIndex((el)=>{return el.order_id == order_id})
        if (t > -1){
          pIdx = t
          oldCategory = cat
        }
      });

      pIdx = this.pedidos[oldCategory].findIndex((el)=>{return el.order_id == order_id})
      let itm = structuredClone(this.pedidos[oldCategory][pIdx])
      this.pedidos[oldCategory].splice(pIdx, 1)
      this.pedidos[newCategory].push(itm)


      this.$nextTick(()=>{
        this.storeStateLocally()
      })
    },
    formatCurrency(value , currency){
      const formatter = new Intl.NumberFormat("pt-BR", { style: "currency", currency: currency });
      return formatter.format(value)
    },
    calculateChange(val1, val2){
      let r = val1 - val2
      if (r < 0){
        return 0
      }
      return r
    },
    storeStateLocally(){
      if (this.pedidos){
        localStorage.setItem('TEMPORARY_IFOOD_STORAGE', JSON.stringify(this.pedidos))
      }
    },
    clearStorage(){
      localStorage.removeItem('TEMPORARY_IFOOD_STORAGE')
      window.location.reload()
    },
    ordersEmpy(){
      let pedidos
    }
    
  },
};  
</script>

<style lang="scss" scoped>
@import '@/assets/scss/custom-utils.scss';

$ifood: #EA1D2C;

.page-header{
  padding: 6px 10px;
  background-color: rgb(207, 207, 207);
  color: $black;
  border-bottom: 2px solid rgb(0, 0, 0);
  h1, h2{
    color: $black;
    font-weight: bolder;
  }
}

.text-ifood{
  color: $ifood;
}


$border: 1px solid rgba(0, 0, 0, 0.5);

.column-container{
  border-collapse: collapse;
  margin: 30px;
  border: $border;

  .product-column{
    border-collapse: collapse;
    width: 25%;
    padding: 3px;
    border: $border;
    background-color: rgba(255, 255, 255, 0.1);
  }
}


.pedidos-outer-container{
  overflow-x: auto;
}
.pedidos-container{
  display: flex;
  justify-content: start;
  align-items: flex-start;
  width: fit-content !important;
}

.pedido-card{
  background-color: $card-blue;
  border-radius: 5px;
  padding: 10px 5px;
  margin: 5px;
  width: 380px;
  border-top: 4px solid;

  .entrega-container{
    background-color: rgba(255, 255, 255, 0.1);
    padding: 3px;
    border-radius: 5px;
    color: white;
  }

  .items-container{
    background-color: rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    padding: 3px;
    max-height: 200px;
    overflow: auto;
    .pedido-item{
      border-bottom: 2px solid rgba(0, 0, 0, 0.5);
      margin: 4px;
      padding-left: 3px;
      padding-bottom: 2px;

      .pedido-observation{
        font-weight: bold;
        color: $secondary;
        font-size: 13px;
      }
    }
    :last-child{
      border-bottom: none;
    }    
  }

  .pagament-info-container{
    padding: 3px;
    border: 2px solid rgba(0, 0, 0, 0.4);
    color: white;
    .title{
      padding: 2px 0;
      background-color: rgba(0, 0, 0, 0.4);
    }
  }
}

.benefit-container{
  padding: 2px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 3px;
}


.created-at-position{
  position: absolute;
  font-weight: bold;
  color: transparentize(white, 0.7);
  top: 5px;
  right: 5px;
  font-size: 11px;
}
</style>

<style lang="scss" >
.modal-dialog{
  max-width: 600px !important;
}
</style>